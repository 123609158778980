/* eslint-disable @typescript-eslint/no-explicit-any */
export interface HendiProduct {
    ProductCode: string | number;
    ProductName: string;
    Stock: number;
    pret_intreg: number;
    pret_vanzare: number;
    EAN: string;
}

export interface HendiStock {
    ProductCode: string;
    StockValue: number;
}

export interface HendiPrice {
    ProductCode: string;
    RRPWithoutVAT: number;
    PriceWithoutVAT: number;
    VAT: number;
    GreenTax: number;
}

export interface HendiOrder {
    Details: string;
    PaymentTypeId?: number;
    OrderLines: HendiOrderLine[];
    Address?: HendiAddress;
}

export interface HendiAddress {
    Name: string;
    Address: string;
    County: string;
    City: string;
    ZipCode: string;
    Phone: string;
    CourierID?: string;
}

export interface HendiOrderLine {
    ProductCode: string;
    Quantity: number;
    PriceWithoutVAT: number;
    Vat: number;
}

export interface HendiOrderResponse {
    Status?: string;
    Response: any;
}

export interface HendiInvoice {
    ClientCIF: string;
    Cancelled: number;
    DownloadURL: string;
    InvoiceDate: Date;
    DueDate: Date;
    SeriesNo: string;
    InvoiceNo: string;
    Outstanding: number;
    Amount: number;
    Lines: HendiInvoiceLine[];
}

export interface HendiInvoiceLine {
    ProductCode: string;
    Qty: number;
    UnitPriceWithoutVAT: number;
    VATP: number;
}

export enum HendiOrderState {
    RETURNED = 12,
    CANCELED = 5,
}

export interface HendiProductDetails {
    Product_Code: string;
    Product_Name: string;
    Enabled: number;
    Stock: number;
    EAN_Barcode: number;
    Description: string;
    Image: string;
    Gallery_Images: string;
    Price: number;
    Ambalare: string;
    Capac_inclus: string;
    Capacitate: string;
    Denumire_Gama: string;
    Diametru_exterior: string;
    Diametru_interior: string;
    Intrebuintare: string;
    Inaltime: string;
    Latime: string;
    Lungime: string;
    Marime_GN: string;
    Material: string;
    Nr__Buc_: string;
    Nr__Zone_Gatit: string;
    Numar_zone_incalzire: string;
    Putere__in_W_: string;
    Scop_Utilizare: string;
    Scurgere: string;
    Temperatura_maxima: string;
    Termostat: string;
    Tip: string;
    Tip_produs: string;
    Tip_suprafata: string;
    Volum: string;
}

export enum HendiFeedType {
    RO = 'RO',
    B2B = 'B2B',
}

export const HendiFeeds = {
    [HendiFeedType.RO]: 'https://www.hendi.ro/userfiles/5e6ea110-92c8-4fcb-8b25-2b85ad01d0d7/feeds/4d2c8e1d-a3cf-4e0f-bbc9-af55bd692992.xml',
    [HendiFeedType.B2B]: 'https://b2b.hendi.ro/userfiles/650ac6e3-9646-41a7-ba4e-41f3ac99c7ea/feeds/8485565f-7673-4ee2-9d76-f7029aad9d94.xml',
};
