import { BoolEnum } from 'sharp';
import { Address } from './address';
import { CurrencyEnum } from './currency';
import { LegalInfo } from './legal-info';
import { Brand, Product, ProductStatusEnum, Tax } from './product';

export interface ProviderContact {
    name: string;
    phone: string;
    email: string;
    type: string;
    address: Address;
}

export interface ProviderFile {
    path: string;
    type: string;
}

export interface ProviderDeadline {
    ['Plata']?: number;
    ['Livrare']?: number;
}

export interface ProviderDaySchedule {
    start: number | null;
    end: number | null;
    delivery?: boolean;
}

export interface ProviderDiscount {
    ['Cantitate']: { value: number; discount: number };
    ['Valoare comanda']: { value: number; discount: number };
}

export interface ProviderDetails {
    ['Luni']?: ProviderDaySchedule;
    ['Marți']?: ProviderDaySchedule;
    ['Miercuri']?: ProviderDaySchedule;
    ['Joi']?: ProviderDaySchedule;
    ['Vineri']?: ProviderDaySchedule;
    ['Sambata']?: ProviderDaySchedule;
    ['Duminica']?: ProviderDaySchedule;
    ['delivery_min_order']?: number;
}

export interface ProviderItemDetails {
    ean?: string;
    dataSheet?: string;
    userManuals?: string[];
    greenTax?: number;
    productDetails?: { [n: string]: any }[];
    [k: string]: any;
}

export interface Provider {
    id: string;
    name: string;
    legalInfoId?: string;
    legal_info?: LegalInfo;
    feeds: string[];
    active: boolean;
    contacts: ProviderContact[];
    files: ProviderFile[];
    order_emails: boolean;
    emails: string[];
    daily_report?: boolean;
    increase_ratio: number;
    last_sync: Date;
    deadlines?: ProviderDeadline;
    discounts?: ProviderDiscount;
    details?: ProviderDetails;
    provider_items: ProviderItem[];
    force_update: boolean;
    created_at: Date;
    updated_at: Date;
}

export interface ProviderList {
    name: string;
    provider_items: { sku: string }[];
    skus: Set<string>;
}

export interface ProviderItem {
    name: string;
    description?: string;
    sku: string;
    ean?: string;
    brandId?: string;
    brand?: Brand;
    status: ProductStatusEnum;
    currency: CurrencyEnum;
    base_price: number;
    discounted_base_price?: number | null;
    taxId?: string;
    tax?: Tax;
    taxRate?: number;
    quantity: number;
    images: string[];
    details?: ProviderItemDetails;
    sync: boolean;
    increase_ratio?: number;
    delete: boolean;
    force_update: boolean;
    deactivate: boolean;
    verified: boolean;
    product?: Product;
    modified?: boolean;
    productId?: string;
    providerId: string;
    provider?: Provider;
}

export enum ProviderItemActionEnum {
    FORCE_UPDATE = 'force-update',
    SYNC = 'sync',
    DELETE = 'delete',
    DEACTIVATE = 'deactivate',
    VERIFIED = 'verified',
}
